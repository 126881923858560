const dev = {
	// API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
	API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
	// API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'
	
  };
  
  const prod = {
	// API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
	API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
	// API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'
  };
  
  const test = {
	// API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
	API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
	// API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'
  };
  
  const getEnv = () => {
	  switch (process.env.NODE_ENV) {
		  case 'development':
			  return dev
		  case 'production':
			  return prod
		  case 'test':
			  return test
		  default:
			  break;
	  }
  }
  
  export const env = getEnv()
  
