import fetch from 'auth/FetchMasterInterceptor'

const masterService = {}
masterService.changePassword = function (data) {
  return fetch({
    url: '/change-password',
    method: 'post',
    data:data
  })
}
masterService.getPlant = function (data) {
  return fetch({
    url: '/plant/list',
    method: 'post',
    data:data
  })
}
masterService.viewPlant = function (data) {
  return fetch({
    url: '/plant/view',
    method: 'post',
    data:data
  })
}
masterService.addPlant = function (data) {
  return fetch({
    url: '/plant/create',
    method: 'post',
    data:data
  })
}
masterService.editPlant = function (data) {
  return fetch({
    url: '/plant/edit',
    method: 'post',
    data:data
  })
}
masterService.deletePlant = function (data) {
  return fetch({
    url: '/plant/delete',
    method: 'post',
    data:data
  })
}


masterService.getSection = function (data) {
  return fetch({
    url: '/section/list',
    method: 'post',
    data:data
  })
}
masterService.addSection = function (data) {    
  return fetch({
    url: '/section/create',
    method: 'post',
    data:data
  })
}
masterService.editSection = function (data) {
  return fetch({
    url: '/section/edit',
    method: 'post',
    data:data
  })
}
masterService.deleteSection = function (data) {
  return fetch({
    url: '/section/delete',
    method: 'post',
    data:data
  })
}
masterService.getRaspberry = function (data) {
  return fetch({
    url: '/raspberry/list',
    method: 'post',
    data:data
  })
}
masterService.addRaspberry = function (data) {
  return fetch({
    url: '/raspberry/add',
    method: 'post',
    data:data
  })
}
masterService.editRaspberry = function (data) {
  return fetch({
    url: '/raspberry/update',
    method: 'post',
    data:data
  })
}
masterService.deleteRaspberry = function (data) {
  return fetch({
    url: '/raspberry/delete',
    method: 'post',
    data:data
  })
}
masterService.getMeter = function (data) {
  return fetch({
    url: '/meter/list',
    method: 'post',
    data:data
  })
}
masterService.addMeter = function (data) {
  return fetch({
    url: '/meter/add',
    method: 'post',
    data:data
  })
}
masterService.editMeter = function (data) {
  return fetch({
    url: '/meter/update',
    method: 'post',
    data:data
  })
}
masterService.deleteMeter = function (data) {
  return fetch({
    url: '/meter/delete',
    method: 'post',
    data:data
  })
}
masterService.showMeter = function (data) {
  return fetch({
    url: '/meter/show',
    method: 'post',
    data:data
  })
}
masterService.getDesignation = function (data) {
  return fetch({
    url: '/meter/delete',
    method: 'post',
    data:data
  })
}
export default masterService